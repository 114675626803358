<template>
  <a-modal
    :title="title"
    :visible="open"
    :confirm-loading="uploading"
    @cancel="importExcelHandleCancel"
    width='1200px'
  >
    <div class="content">
      <a-table
        :scroll="{ x: '130%' }"
        :loading="loading"
   
        rowKey="goodsCode"
        @change="handleSortChange"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="false"
      >

      <span slot="goodsName" slot-scope="text, record">
        <div class="goodsNameClass" :title="record.goodsName">
          {{record.goodsName}}
        </div>
      </span>
  
        <span slot="operation" slot-scope="text, record">
              <a-divider type="vertical" v-hasPermi="['goods:goods:edit']"/>
              <a @click="handleUpdate(record)" v-hasPermi="['goods:goods:edit']">
                <a-icon type="import"/>{{ $t('通用.按钮.导入') }}
              </a>
            </span>
      </a-table>
      <!-- 4:分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </div>

    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button @click="importExcelHandleCancel">取 消</a-button>
        <a-button type="primary" :loading="loadingBtn" @click="importExcelHandleChange" class="submitClass">保 存</a-button>
        <a-button type="primary" :loading="loadingImport" @click="importAll" class="submitClass">导入全部</a-button>
      </div>

  </a-modal>
</template>

<script>


import CustomDictTag from '@/components/DictCustomTag/index.vue'
import { commodityPool, pageGoods } from '@/api/goods/goods'

export default {
  name: 'ShoppingCart',
  props: {
  },
  components: {
    CustomDictTag
  },
  data () {
    return {
      list1:[
        {
          id:1,
          name:'11111'
        },
        {
          id:2,
          name:'22222'
        }, {
          id:3,
          name:'33333'
        }, {
          id:4,
          name:'44444'
        }
      ],
      list2:[
        {
          id:1,
          name:'11111'
        },
        {
          id:2,
          name:'22222'
        }
      ],
      selectData:[],//选中的数据
      loadingImport:false,
      loadingBtn:false,
      title: '商品池',
      open: false,
      uploading: false,
      loading: false,
      selectedRowKeys: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      total: 0,
      ids:[],
      queryParam: {
        pageNum: 1,
        pageSize: 10
      },
      list: [{
        "id":1,
        "goodsName":1,
        "distributionPrice":1,
        "propertiesValue":1,
        "brand":1,
        "qty":1
      }],
      columns: [
        {
          title: '商品名称',
          dataIndex: 'goodsName',
        
          scopedSlots: { customRender: 'goodsName' },
          ellipsis: true,
          fixed: 'left',
        },
        {
          title: '商品价',
          dataIndex: 'price',
          width: 100,
        },
        {
          title: '商品库存',
          dataIndex: 'stock',
          width: 100,
        },
        {
          title:'品牌名称',
          dataIndex: 'brandName',
          width: 100,
        },
        {
          title: '参数',
          dataIndex: 'parameterSet',
          width: 100,
        },{
          title: '商品参数',
          dataIndex: 'propertiesValue',
          width: 150,
        },
        {
          title:'重量',
          dataIndex: 'weight',
          width: 100,
        },
        {
          title: '单位',
          dataIndex: 'unit',
          width: 100,
        },

        {
          title: '长',
          dataIndex: 'l',
          width: 100,
        },
        {
          title: '宽',
          dataIndex: 'w',
          width: 100,
        },
        {
          title: '高',
          dataIndex: 'h',
          width: 100,
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          fixed: 'right',
          width: 210,
          scopedSlots: {customRender: 'operation'},
        }
      ]
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    //导入
    handleUpdate(row){
      console.log(row,'当前导入行详情！');
      
    },
    

    getTotal(total) {
      return this.$t('通用.文本.共') + total + this.$t('通用.文本.条')
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.importExcelHandleOpen()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.importExcelHandleOpen()
    },
    handleSortChange(pagination, filters, sorter) {    
      // this.filteredInfo = filters
      // this.sortedInfo = sorter
      // if (sorter.order) {
      //   /** 排序字段 sortField **/
      //   /** 排序方式  sortOrder**/
      //   this.queryParam.sortField = sorter.columnKey
      //   this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
      //   this.getList()
      // } else {
      //   this.queryParam.sortField = '';
      //   this.queryParam.sortOrder = ''
      //   this.getList()
      // }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.goodsCode)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
      //当前页未选中的数据
      let noSelect = this.noSelectData(this.selectedRows)
      
      // 添加未选中的数据
       selectedRows.forEach((res) => {
        const result = this.selectData.findIndex(
          (val) => val.goodsCode === res.goodsCode
        );
        if (result === -1) {
          this.selectData.push(res);
        }
      });

      // 去除已选中的数据
       noSelect.forEach((res) => {
        const result = this.selectData.findIndex(
          (val) => val.goodsCode === res.goodsCode
        );
        if (result != -1) {
          this.selectData.splice(result, 1);
        }
      });
    },

    // 获取当前页未选中的数据
    noSelectData(data){
      return this.list.filter(item1 => 
        !data.some(item2 => item2.goodsCode === item1.goodsCode)
      );
    },
    /** 商品池窗体关闭 */
    importExcelHandleCancel (e) {
      this.open = false
      this.clearData()
      // 关闭后刷新列表
      this.$emit('ok')
    },

    /** 商品池窗体开启 */
    importExcelHandleOpen (e) {
      this.open = true
      commodityPool(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
      })
    },
    /** 导入excel */
    importExcelHandleChange () {
      // 使用 setTimeout 设置定时器，3秒后执行
    console.log(this.selectData,'当前选中的数据！');
      // 如果只需要goodsCode 字段
      // const goodsCodeArray = this.selectData.map(item => item.goodsCode);
      if(this.selectData.length>0){
        this.uploading = true
        this.loadingBtn = true
        setTimeout(() => {
          this.open = false
          this.uploading = false
          this.loadingBtn = false
          this.clearData()
          // 关闭后刷新列表
          this.$emit('ok')
        }, 3000); // 3秒后触发

      }else{
        this.$message.info("请选择商品!")
      }
      
      
    },

    // 数据清空
    clearData(){
      this.selectData = []
      this.selectedRowKeys = []
      this.selectedRows = []
      this.ids = []
    },
    /** 导入全部 */
    importAll(){
      this.loadingImport = true
      setTimeout(() => {
        this.loadingImport = false
        that.$message.success("导入成功!")
      }, 1000);
    }
  }
}
</script>
<style>
.content {
  width: 100%;
  margin: 0px 0px 50px 0px;
}

.submitClass{
  margin-left: 10px;
}

.goodsNameClass{
  width: 100px;
  white-space: nowrap;
   overflow: hidden;
    text-overflow: ellipsis;

}
</style>